/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button } from "../components/Button";
import { SelectField, TextField } from "../components/Fields";
import { AuthLayout } from "../components/AuthLayout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Audio, BallTriangle, Circles } from "react-loader-spinner";

function Home() {
  const [checkNumber, setCheckNumber] = useState(null);
  const [checkNumberLoading, setCheckNumberLoading] = useState(null);
  const [checkNumberMessage, setCheckNumberMessage] = useState(null);
  const [selectActionMessage, setSelectActionMessage] = useState(null);
  const [verifyCodeMessage, setVerifyCodeMessage] = useState(null);
  const [newTradingLoading, setNewTradingLoading] = useState(null);
  const [newTradingMessage, setNewTradingMessage] = useState(null);
  const [newTradingAccount, setNewTradingAccount] = useState({
    old: "",
    new: "",
    pass: "",
    ipass: "",
    action: "cont",
    vcode: "",
  });
  const [agreed, setAgreed] = useState(false);
  console.log(window.location.search);
  console.log(newTradingAccount);
  const [searchParams] = useSearchParams();
  const [spinner, setSpinner] = useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token) {
      localStorage.setItem("access_token", token);
    }

    if (refresh) {
      localStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      return navigate("/selfservice");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let old = localStorage.getItem("check_number");
    let code = localStorage.getItem("vcode");
    let action = localStorage.getItem("action");
    console.log(old);
    console.log(code);
    console.log(action);

    setNewTradingAccount({
      ...newTradingAccount,
      old: old,
      action: action,
      vcode: code,
    });

    // localStorage.getItem("check_number");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localStorage.getItem("check_number"),
    localStorage.getItem("vcode"),
    localStorage.getItem("action"),
  ]);

  React.useEffect(() => {
    if (window.location.search === "?newNumber") {
      setSpinner(false);
    }
    if (!localStorage.getItem("action")) {
      localStorage.setItem("action", "cont");
    }
  }, []);

  return (
    <>
      {/* <Head>
            <title>FT9ja - Register</title>
            <meta
              name="description"
              content="Select your desired account size, pay, and start trading in less than 24 hours. If you encounter any issues on this page, please let us know immediately on Whatsapp (08138462394)."
            />
          </Head> */}
      {spinner ? (
        <div className="w-[100%] h-[100vh] flex items-center justify-center">
          <Circles
            height="150"
            width="150"
            // radius="9"
            color="green"
            ariaLabel="loading"
            // wrapperStyle
            // wrapperClass
          />
        </div>
      ) : (
        <AuthLayout
        // title="Sign up for an account"
        // subtitle={
        //   <>
        //     Already registered?{" "}
        //     <Link to="/login" className="">
        //       <span className="cursor-pointer text-[#28a745]">Sign in</span>
        //     </Link>{" "}
        //     to your account.
        //   </>
        // }
        >
          {window.location.search !== "?newNumber" &&
            window.location.search !== "?checkNumber" &&
            window.location.search !== "?verifyCode" &&
            window.location.search !== "?support" && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (
                    localStorage.getItem("action") &&
                    localStorage.getItem("action") === "cont"
                  ) {
                    window.location.href = "/selfservice?checkNumber";
                  } else if (
                    localStorage.getItem("action") &&
                    (localStorage.getItem("action") === "add_aso" ||
                      localStorage.getItem("action") === "add_sca")
                  ) {
                    window.location.href = "/selfservice?verifyCode";
                  } else {
                    setSelectActionMessage("Action type is required");
                  }
                }}
              >
                <div className="grid grid-cols-1 gap-6">
                  <SelectField
                    className="col-span-full"
                    label="Action Type"
                    id="action_type"
                    name="action_type"
                    // required
                    value={localStorage.getItem("action")}
                    onChange={(e) => {
                      console.log(e.target.value);
                      localStorage.setItem("action", e.target.value);
                      setNewTradingAccount({
                        ...newTradingAccount,
                        action: e.target.value,
                      });
                    }}
                  >
                    <option value="add_sca">Add SCA</option>
                    <option value="add_aso">Add Aso</option>
                    <option value="cont">Deriv account continuation</option>
                  </SelectField>
                </div>
                <p className="text-center">{selectActionMessage}</p>
                <Button
                  type="submit"
                  color="cyan"
                  className="mt-8 mb-5 w-full duration-200 hover:bg-gray-800 focus:bg-[#28a745] focus:text-white/80"
                >
                  Continue
                </Button>
                <a
                  className="underline"
                  href="https://ft9ja.notion.site/How-to-Connect-Your-Account-to-Your-Dashboard-Using-FT9ja-Self-Service-b5ada3fcd6c645e6b5373c12dcf0ee14 "
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-center">Self service guide</p>
                </a>
              </form>
            )}

          {window.location.search === "?verifyCode" && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCheckNumberLoading(true);

                axios
                  .get(
                    `${`https://maindashbe-june-b18731a0e161.herokuapp.com/selfservice/check-verification-code/${
                      newTradingAccount.vcode
                    }/${
                      newTradingAccount.action === "add_sca" ? "sca" : "aso"
                    }`}`,
                    {
                      headers: {
                        Authorization: localStorage.getItem("access_token")
                          ? "Bearer " + localStorage.getItem("access_token")
                          : null,
                        "Content-Type": "application/json",
                        accept: "application/json",
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res);
                    setSpinner(true);
                    localStorage.setItem("check_number", res.data.account);
                    setVerifyCodeMessage(res.data.message);
                    setCheckNumberLoading(false);
                    if (
                      res.data.message === "Verification code is valid" &&
                      window.location.search !== "?support"
                    ) {
                      window.location.href = "/selfservice?newNumber";
                    } else {
                      setSpinner(false);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    console.log(err.response.data);
                    setCheckNumberLoading(false);
                  });
              }}
            >
              <div className="grid grid-cols-1 gap-6">
                <h1 className="text-center font-medium text-[1.3rem]">
                  {newTradingAccount.action === "add_sca"
                    ? "Add Second Chance Account"
                    : "Add Aso Account"}
                </h1>
                <TextField
                  className="col-span-full"
                  label="Verification Code"
                  id="verification_code"
                  name="verification_code"
                  type="verification_code"
                  value={newTradingAccount.vcode}
                  onChange={(e) => {
                    localStorage.setItem("vcode", e.target.value);
                    setNewTradingAccount({
                      ...newTradingAccount,
                      vcode: e.target.value,
                    });
                  }}
                  autoComplete="verification_code"
                  required={
                    newTradingAccount.action === "add_sca" ||
                    newTradingAccount.action === "add_aso"
                  }
                />
              </div>
              <p className="text-center">{verifyCodeMessage}</p>
              <Button
                type="submit"
                color="cyan"
                className="mt-8 mb-5 w-full duration-200 hover:bg-gray-800 focus:bg-[#28a745] focus:text-white/80"
              >
                {checkNumberLoading ? "Checking" : "Check"}
              </Button>
              <a
                className="underline"
                href="https://ft9ja.notion.site/How-to-Connect-Your-Account-to-Your-Dashboard-Using-FT9ja-Self-Service-b5ada3fcd6c645e6b5373c12dcf0ee14 "
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-center">Self service guide</p>
              </a>
            </form>
          )}

          {(window.location.search === "?checkNumber" ||
            window.location.search === "?support") && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCheckNumberLoading(true);

                axios
                  .post(
                    `${
                      window.location.search === "?support"
                        ? "https://maindashbe-june-b18731a0e161.herokuapp.com/selfservice/support/check-number/"
                        : "https://maindashbe-june-b18731a0e161.herokuapp.com/selfservice/check-number/"
                    }`,
                    {
                      number: checkNumber,
                    },
                    {
                      headers: {
                        Authorization: localStorage.getItem("access_token")
                          ? "Bearer " + localStorage.getItem("access_token")
                          : null,
                        "Content-Type": "application/json",
                        accept: "application/json",
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res);
                    setSpinner(true);
                    localStorage.setItem("check_number", checkNumber);
                    setCheckNumberMessage(res.data.message);
                    setCheckNumberLoading(false);
                    if (
                      res.data.message === "Account is active" &&
                      window.location.search !== "?support"
                    ) {
                      window.location.href = "/selfservice?newNumber";
                    } else {
                      setSpinner(false);
                    }
                  })
                  .catch((err) => {
                    console.log(err.response.data);
                  });
              }}
            >
              <div className="grid grid-cols-1 gap-6">
                <h1 className="text-center font-medium text-[1.3rem]">
                  Deriv Account Continuation
                </h1>
                <TextField
                  label="Check number"
                  id="check_number"
                  name="check_number"
                  type="name"
                  value={checkNumber}
                  onChange={(e) =>
                    setCheckNumber(e.target.value.replace(/[^0-9]/g, ""))
                  }
                  autoComplete="check_number"
                  required
                />
              </div>
              <p className="text-center">{checkNumberMessage}</p>
              <Button
                type="submit"
                color="cyan"
                className="mt-8 mb-5 w-full duration-200 hover:bg-gray-800 focus:bg-[#28a745] focus:text-white/80"
              >
                {checkNumberLoading ? "Checking" : "Check"}
              </Button>
              <a
                className="underline"
                href="https://ft9ja.notion.site/How-to-Connect-Your-Account-to-Your-Dashboard-Using-FT9ja-Self-Service-b5ada3fcd6c645e6b5373c12dcf0ee14 "
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-center">Self service guide</p>
              </a>
            </form>
          )}

          {window.location.search === "?newNumber" && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setNewTradingLoading(true);
                if (newTradingAccount.ipass === "") {
                  setNewTradingMessage("Investor password is required");
                }

                if (newTradingAccount.pass === "") {
                  setNewTradingMessage("Password is required");
                }

                if (newTradingAccount.new === "") {
                  setNewTradingMessage(
                    "New trading account number is required"
                  );
                }
                if (
                  newTradingAccount.action === "add_aso" ||
                  newTradingAccount.action === "add_sca"
                ) {
                  if (newTradingAccount.vcode === "") {
                    setNewTradingMessage("Verification code is required");
                  }
                }

                if (
                  newTradingAccount.ipass !== "" &&
                  newTradingAccount.pass !== "" &&
                  newTradingAccount.new !== ""
                ) {
                  axios
                    .post(
                      "https://maindashbe-june-b18731a0e161.herokuapp.com/selfservice/add-new-deriv/",
                      {
                        old_number: newTradingAccount.old,
                        new_number: newTradingAccount.new,
                        password: newTradingAccount.pass,
                        investor_password: newTradingAccount.ipass,
                        service_type:
                          newTradingAccount.action === "add_sca"
                            ? "sca"
                            : newTradingAccount.action === "add_aso"
                            ? "aso"
                            : "continuation",
                        verification_code: newTradingAccount.vcode,
                      },
                      {
                        headers: {
                          Authorization: localStorage.getItem("access_token")
                            ? "Bearer " + localStorage.getItem("access_token")
                            : null,
                          "Content-Type": "application/json",
                          accept: "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      console.log(res);
                      if (res.data.message) {
                        setNewTradingMessage(res.data.message);
                      }
                      //   window.location.href = `https://dashboard.ft9ja.com/dashboards?token=${localStorage.getItem(
                      //     "access_token"
                      //   )}&refresh_token=${localStorage.getItem("refresh_token")}`;
                      setNewTradingLoading(false);
                      if (
                        res.data.message !== "Account type not supported" &&
                        res.data.message !==
                          "Old account already has a new account" &&
                        res.data.message !== "Invalid verification code" &&
                        res.data.message !==
                          "You have an active Aso account already."
                      ) {
                        window.location.href = `https://dashboard.ft9ja.com/dashboards?token=${localStorage.getItem(
                          "access_token"
                        )}&refresh_token=${localStorage.getItem(
                          "refresh_token"
                        )}`;
                        //   window.location.href = "/confimemail";
                      }
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response.status === 500) {
                        setNewTradingMessage(
                          "Server error, please contact support"
                        );
                      }
                    });
                }
              }}
            >
              <div className="grid grid-cols-1 gap-6">
                <h1 className="text-center font-medium text-[1.3rem]">
                  {newTradingAccount.action === "add_sca"
                    ? "Add Second Chance Account"
                    : newTradingAccount.action === "add_aso"
                    ? "Add Aso Account"
                    : "Deriv Account Continuation"}
                </h1>
                <TextField
                  label={
                    newTradingAccount.action === "add_sca"
                      ? "First Zuma Trading Account Number"
                      : newTradingAccount.action === "add_aso"
                      ? "Qualified Zuma/SCA Trading Account Number"
                      : "Old Trading Account Number"
                  }
                  id="old_trading_account_number"
                  name="old_trading_account_number"
                  type="name"
                  value={newTradingAccount.old}
                  // onChange={(e) =>
                  //   setNewTradingAccount({
                  //     ...newTradingAccount,
                  //     old: e.target.value.replace(/[^0-9]/g, ""),
                  //   })
                  // }
                  autoComplete="old_trading_account_number"
                  required
                />
                <TextField
                  label="New Trading Account number"
                  id="new_trading_account_number"
                  name="new_trading_account_number"
                  type="name"
                  value={newTradingAccount.new}
                  onChange={(e) =>
                    setNewTradingAccount({
                      ...newTradingAccount,
                      new: e.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                  autoComplete="given-name"
                  required
                />
                <TextField
                  className="col-span-full"
                  label="Investor Password"
                  id="investor_password"
                  name="investor_password"
                  type="investor_password"
                  value={newTradingAccount.ipass}
                  onChange={(e) =>
                    setNewTradingAccount({
                      ...newTradingAccount,
                      ipass: e.target.value,
                    })
                  }
                  autoComplete="investor_password"
                  required
                />
                <TextField
                  className="col-span-full"
                  label="Trading Password"
                  id="trading_password"
                  name="trading_password"
                  type="trading_password"
                  value={newTradingAccount.pass}
                  onChange={(e) =>
                    setNewTradingAccount({
                      ...newTradingAccount,
                      pass: e.target.value,
                    })
                  }
                  autoComplete="trading_passowrd"
                  required
                />
                {(newTradingAccount.action === "add_sca" ||
                  newTradingAccount.action === "add_aso") && (
                  <TextField
                    className="col-span-full"
                    label="Verification Code"
                    id="verification_code"
                    name="verification_code"
                    type="verification_code"
                    value={newTradingAccount.vcode}
                    // onChange={(e) =>
                    //   setNewTradingAccount({
                    //     ...newTradingAccount,
                    //     vcode: e.target.value,
                    //   })
                    // }
                    autoComplete="verification_code"
                    required={
                      newTradingAccount.action === "add_sca" ||
                      newTradingAccount.action === "add_aso"
                    }
                  />
                )}
                <TextField
                  className="col-span-full"
                  label="Server"
                  id="server"
                  name="server"
                  type="server"
                  value={"deriv-demo"}
                  //   onChange={(e) => {
                  //     setEmailError("")
                  //     setEmail(e.target.value)
                  //   }}
                  autoComplete="server"
                  required
                />
                <TextField
                  className="col-span-full"
                  label="Platform"
                  id="platform"
                  name="platform"
                  type="platform"
                  value={"MT5"}
                  //   onChange={(e) => {
                  //     setEmailError("")
                  //     setEmail(e.target.value)
                  //   }}
                  autoComplete="platform"
                  required
                />
              </div>
              <p className="text-center text-red-500 mb-5">
                {newTradingMessage}
              </p>
              <div>
                <p>
                  Tampering with the account details (login, password, leverage,
                  topping up the balance) without written approval from FT9ja
                  violates the rule.
                </p>
                <div className="flex items-center gap-2 mt-5">
                  <input
                    type="checkbox"
                    onClick={() => setAgreed(!agreed)}
                    checked={agreed}
                  />
                  <p>I have read and agreed with the rules and terms</p>
                </div>
              </div>
              {agreed && (
                <Button
                  type="submit"
                  color="cyan"
                  className="mt-8 w-full duration-200 hover:bg-gray-800 focus:bg-[#28a745] focus:text-white/80"
                >
                  {newTradingLoading ? "Please Wait..." : "Submit"}
                </Button>
              )}
            </form>
          )}
        </AuthLayout>
      )}
    </>
  );
}

export default Home;
